import Vapi from 'vuex-rest-api';

const platform = new Vapi({
  state: {
    platforms: [],
  },
})
  .post({
    action: 'newPlatform',
    property: 'platform',
    path: ({ podcastId }) => `/podcasts/${podcastId}/platforms/`,
  })
  .get({
    action: 'getPlatforms',
    property: 'platforms',
    path: ({ podcastId }) => `/podcasts/${podcastId}/platforms/`,
    queryParams: true,
  })
  .delete({
    action: 'deletePlatform',
    property: 'platform',
    path: ({ podcastId, platformId }) => `/podcasts/${podcastId}/platforms/${platformId}`,
  });

export default platform.getStore({
  namespaced: true,
});
