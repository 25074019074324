// Vue.js Filters
// https://vuejs.org/v2/guide/filters.html
import { extractHostname } from './utils';
import { formatTimestamp } from './utils/dates';

const filters = {
  extractHostname,
  formatTimestamp,
  formatNumber(number) {
    return Number(number).toLocaleString();
  },
  formatPercentatge(number) {
    return `${number.toFixed(2)}%`;
  },
};

export default filters;
