import Vapi from 'vuex-rest-api';

const episode = new Vapi({
  state: {
    media: [],
  },
})
  .post({
    action: 'newMedia',
    property: 'medium',
    path: '/media/',
  })
  .put({
    action: 'updateMedium',
    property: 'medium',
    path: ({ id }) => `/media/${id}`,
  })
  .get({
    action: 'getMedium',
    property: 'medium',
    path: ({ id }) => `/media/${id}/`,
  })
  .get({
    action: 'getMedia',
    property: 'medium',
    path: '/media/',
  });

export default episode.getStore({
  namespaced: true,
});
