import axios from 'axios';

const initialEpisodeStruct = {
  title: '',
  summary: '',
  image: '',
  media: '',
  mediaURL: '',
  video: '',
  visualization_image: '',
  published: false,
  scheduled_date: null,
  publication_date: null,
  decodedSVG: '',
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  getLatestEpisode({ commit }, podcastId) {
    return axios.get(`podcasts/${podcastId}/episodes/latest/`);
  },
  setEpisode({ commit }, episode) {
    const data = {
      ...episode,
      media: episode.media.url,
      published: episode.state === 'published',
      transcribed: !!episode.media?.transcript_text,
      visualization_image: episode.media.visualization_image
        ? episode.media.visualization_image : '',
      video: episode.youtube_video_link,
      scheduled_date: episode.scheduled_date ? new Date(episode.scheduled_date) : null,
      publication_date: episode.state === 'published' ? new Date(episode.publication_date) : null,
      decodedSVG: episode.media.visualization_image ? decodeURIComponent(
        escape(window.atob(episode.media.visualization_image)),
      ) : null,
    };
    commit('UPDATE_EPISODE', data);
  },
};

const mutations = {
  RESET_EPISODE(state) {
    state.episode = { ...initialEpisodeStruct };
    state.oldEpisode = { ...initialEpisodeStruct };
  },
  UPDATE_EPISODE(state, payload) {
    state.episode = { ...state.episode, ...payload };
  },
  UPDATE_OLD_EPISODE(state, payload) {
    state.oldEpisode = payload;
  },
};

const getters = {
  getEpisodeData: ({ episode }) => (state, podcastId) => {
    const episodeData = {
      title: episode.title,
      summary: episode.summary,
      visualization_image: episode.visualization_image,
      state,
      podcast_id: podcastId,
    };
    if (episode.image) {
      episodeData.image = episode.image;
    }
    if (episode.media) {
      episodeData.media = episode.media;
    }
    if (episode.video) {
      episodeData.youtube_video_link = episode.video;
    }
    if (!episode.published && episode.scheduled_date) {
      episodeData.scheduled_date = episode.scheduled_date.toISOString();
    }
    if (episode.published) {
      episodeData.publication_date = episode.publication_date.toISOString();
    }
    return episodeData;
  },
  getSteps: ({ episode }) => [
    {
      id: 0,
      hash: '#title_description',
      button: 'Set cover',
    },
    {
      id: 1,
      hash: '#episode_cover',
      button: 'Set media',
    },
    {
      id: 2,
      hash: '#audio',
      button: 'Wrap up',
    },
    {
      id: 3,
      hash: '#video',
      button: 'Save',
    },
    {
      id: 4,
      hash: '#publication',
      button: episode.published ? 'Save' : 'Schedule and save',
    },
  ],
  getActiveStep: (_, { getSteps: steps }) => ({ id = '', hash = '' }) => {
    let step;
    if (id.toString()) {
      step = steps.find((i) => i.id === id);
    } else if (hash) {
      step = steps.find((i) => i.hash === hash);
    }
    if (!step) return steps[0];
    return step;
  },
  currentStepCleared: (state) => (activeStep) => {
    let cleared = false;
    switch (activeStep) {
      case 0:
        if (state.episode.title && state.episode.summary !== '' && state.episode.summary !== '<p data-empty-text="Episode description" class="is-empty is-editor-empty"><br></p>' && state.episode.summary !== '<p></p>') cleared = true;
        break;
      case 1:
        if (state.episode.image && state.episode.title && state.episode.summary !== '' && state.episode.summary !== '<p data-empty-text="Episode description" class="is-empty is-editor-empty"><br></p>' && state.episode.summary !== '<p></p>') cleared = true;
        break;
      case 2:
        if (state.episode.media !== '') cleared = true;
        break;
      case 3:
        cleared = true;
        break;
      case 4:
        if (state.episode.published) cleared = true;
        if (state.episode.scheduled_date) cleared = true;
        break;
      default:
        /* TODO: Shouldn't be able to reach this state. Report error. */
        break;
    }
    return cleared;
  },
  potentiallyHasUnsavedContent({ episode, oldEpisode }) {
    const scheduledDateTime = episode.scheduled_date?.getTime() ?? -1;
    const oldScheduledDateTime = oldEpisode.scheduled_date?.getTime() ?? -1;

    const publicationDateTime = episode.publication_date?.getTime() ?? -1;
    const oldPublicationDateTime = oldEpisode.publication_date?.getTime() ?? -1;

    return (
      episode.title !== oldEpisode.title
         || episode.summary !== oldEpisode.summary
        || episode.image !== oldEpisode.image
         || episode.media !== oldEpisode.media
        || episode.video !== oldEpisode.video
         || scheduledDateTime !== oldScheduledDateTime
        || publicationDateTime !== oldPublicationDateTime
    );
  },
};

const state = {
  episode: {
    ...initialEpisodeStruct,
  },
  oldEpisode: {
    ...initialEpisodeStruct,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
