import Vapi from 'vuex-rest-api';

const podcast = new Vapi({
  state: {
    podcasts: [],
  },
})
  .post({
    action: 'newPodcast',
    property: 'podcast',
    path: '/podcasts/',
  })
  .put({
    action: 'updatePodcast',
    property: 'podcast',
    path: ({ id }) => `/podcasts/${id}`,
  })
  .get({
    action: 'getPodcasts',
    property: 'podcasts',
    path: '/podcasts/',
  })
  .get({
    action: 'getPodcast',
    property: 'podcast',
    path: ({ id }) => `/podcasts/${id}`,
  });

export default podcast.getStore({
  namespaced: true,
});
