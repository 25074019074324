import Vapi from 'vuex-rest-api';

const category = new Vapi({
  state: {
    categories: {},
  },
})
  .get({
    action: 'getCategories',
    property: 'categories',
    path: () => '/podcasts/categories/',
  });

export default category.getStore({
  namespaced: true,
});
