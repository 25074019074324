export function formatTimestamp(timestamp, format) {
  if (timestamp === null) {
    return '';
  }
  const datetime = new Date(timestamp);
  let options = {};
  if (format === 'mmm dd, yyyy') {
    options = {
      month: 'short',
      day: '2-digit',
      year: 'numeric',
    };
  } else if (format === 'mmmm dd, yyyy') {
    options = {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
    };
  } else if (format === 'mmm yyyy') {
    options = {
      month: 'short',
      year: 'numeric',
    };
  } else {
    options = {
      hour12: true,
      minute: '2-digit',
      hour: '2-digit',
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZoneName: 'short',
    };
  }
  return datetime.toLocaleString(undefined, options);
}

export function getFirstDayMonth(input) {
  const date = input ? new Date(input) : new Date();
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getMonthsBoundaries(option) {
  const start = getFirstDayMonth();
  const end = getFirstDayMonth();
  switch (option.toLowerCase()) {
    case 'last twelve months':
      start.setFullYear(start.getFullYear() - 1);
      start.setMonth(start.getMonth() + 1);
      return [start, end];
    case 'last three months':
      start.setMonth(start.getMonth() - 2);
      return [start, end];
    default:
      return [start, end];
  }
}
