import axios from 'axios';

const actions = {
  requestMagic({ commit }, userData) {
    commit('magic_request', userData);

    return axios.post('login/', userData);
  },
  login({ commit }, loginData) {
    return new Promise((resolve, reject) => {
      commit('auth_request');
      axios
        .get('login/', { params: loginData })
        .then((resp) => {
          const token = resp.data.access_token;
          const refresh = resp.data.refresh_token;
          const userId = Number(resp.data.user_id);
          const { authorized } = resp.data;
          const subscriptionState = resp.data.subscription_state;
          const trialEnd = resp.data.stripe_trial_end;

          commit('auth_success', {
            token,
            refresh,
            userId,
            authorized,
            subscriptionState,
            trialEnd,
          });

          resolve(resp);
        })
        .catch((err) => {
          commit('auth_error');
          reject(err);
        });
    });
  },
  logout({ commit }) {
    return new Promise((resolve) => {
      commit('logout');
      resolve();
    });
  },
  refreshAccessToken({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      axios
        .post('refresh/', {}, { refresh: true, skipAuthRefresh: true })
        .then((resp) => {
          const token = resp.data.access_token;
          commit('refresh_success', { token });

          resolve();
        })
        .catch((err) => {
          dispatch('logout');
          reject(err);
        });
    });
  },
};

const mutations = {
  magic_request(state, payload) {
    state.magicLinkEmail = payload.email;
  },
  auth_request(state) {
    state.status = 'loading';
  },
  auth_success(state, payload) {
    state.status = 'success';
    state.token = payload.token;
    state.refresh = payload.refresh;
    state.userId = payload.userId;
    state.authorized = payload.authorized;
    state.subscriptionState = payload.subscriptionState;
    state.trialEnd = payload.trialEnd;

    localStorage.setItem('token', payload.token);
    localStorage.setItem('refresh', payload.refresh);
    localStorage.setItem('user_id', payload.userId);
    localStorage.setItem('authorized', payload.authorized);
    localStorage.setItem('subscriptionState', payload.subscriptionState);
    localStorage.setItem('trialEnd', payload.trialEnd);
  },
  refresh_success(state, payload) {
    state.token = payload.token;

    localStorage.setItem('token', payload.token);
  },
  auth_error(state) {
    state.status = 'error';
    state.token = '';
    state.refresh = '';
    state.userId = -1;
    state.authorized = false;
    state.subscriptionState = '';
    state.trialEnd = '';

    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('user_id');
    localStorage.removeItem('authorized');
    localStorage.removeItem('subscriptionState');
    localStorage.removeItem('trialEnd');
  },
  logout(state) {
    state.status = '';
    state.token = '';
    state.refresh = '';
    state.userId = -1;
    state.authorized = false;
    state.subscriptionState = '';
    state.trialEnd = '';

    localStorage.removeItem('token');
    localStorage.removeItem('refresh');
    localStorage.removeItem('user_id');
    localStorage.removeItem('authorized');
    localStorage.removeItem('subscriptionState');
    localStorage.removeItem('trialEnd');
  },
};

const getters = {
  isLoggedIn: (state) => !!state.token,
  authStatus: (state) => state.status,
  getToken: (state) => state.token,
  getRefresh: (state) => state.refresh,
  getUserId: (state) => state.userId,
  getUserAuthorized: (state) => state.authorized,
  getSubscriptionState: (state) => state.subscriptionState,
  getTrialEnd: (state) => state.trialEnd,
};

const state = {
  // single source of data
  status: '',
  token: localStorage.getItem('token') || '',
  refresh: localStorage.getItem('refresh') || '',
  userId: Number(localStorage.getItem('user_id')) || -1,
  authorized: JSON.parse(localStorage.getItem('authorized')) || false,
  magicLinkEmail: '',
  subscriptionState: localStorage.getItem('subscriptionState') || '',
  trialEnd: localStorage.getItem('trialEnd') || '',
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
