import Vapi from 'vuex-rest-api';

const user = new Vapi({
  state: {
    user: {},
  },
})
  .put({
    action: 'updateUser',
    property: 'user',
    path: ({ id }) => `/users/${id}`,
  })
  .get({
    action: 'getUser',
    property: 'user',
    path: ({ id }) => `/users/${id}`,
  });

export default user.getStore({
  namespaced: true,
});
