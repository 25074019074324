// utils/index.js

export function filterEmpty(data) {
  return Object.entries(data).reduce(
    (a, [k, v]) => (v == null || v === '' ? a : { ...a, [k]: v }),
    {},
  );
}

export function composeErrorMsg(data) {
  let errorsObject = {};

  if (Object.prototype.hasOwnProperty.call(data, 'query')) {
    errorsObject = data.query;
  } else {
    errorsObject = data.json;
  }

  const errorArrays = Object.values(errorsObject);
  let errorMessage = '';

  errorArrays.forEach((value) => {
    value.forEach((error) => {
      errorMessage += `${error} `;
    });

    errorMessage = errorMessage.slice(0, -1);
    errorMessage += '. ';
  });

  return errorMessage;
}

export function checkNested(obj, level, ...rest) {
  if (obj === undefined) return false;

  if (rest.length === 0 && Object.prototype.hasOwnProperty.call(obj, level)) return true;

  return checkNested(obj[level], ...rest);
}

export function objectEquals(a, b) {
  return JSON.stringify(a) === JSON.stringify(b);
}

export function extractHostname(url) {
  if (!url) {
    return null;
  }

  let hostname;

  // find & remove protocol (http, ftp, etc.) and get hostname
  if (url.indexOf('//') > -1) {
    [, , hostname] = url.split('/');
  } else {
    [hostname] = url.split('/');
  }

  // find & remove port number
  [hostname] = hostname.split(':');
  // find & remove "?"
  [hostname] = hostname.split('?');
  // find and remove "www."
  hostname = hostname.startsWith('www.') ? hostname.split('www.')[1] : hostname;

  return hostname;
}

export function switchRoute(name) {
  if (this.$route.name !== name) {
    this.$router.push({
      name,
      params: this.$route.params,
    });
  }
}

export class Upload {
  constructor(type) {
    this.uploadType = type;
    this.categories = {
      image: {
        name: 'image',
        host: 'https://images.caproni.fm/',
        errorMessages: {
          square: 'Image needs a one by one ratio i.e. perfectly square',
          minWidth: (width, height) => `Image must be at least ${width}x${height} pixels`,
          maxWidth: 'Image must not be larger than 3000x3000 pixels',
        },
      },
      media: {
        name: 'media',
        host: 'https://media.caproni.fm/',
        errorMessages: {},
      },
      file: {
        name: 'download',
        host: 'https://products.caproni.fm/',
        errorMessages: {},
      },
    };
    this.imageTypes = Object.freeze({
      LOGO: 'logo',
      HERO_IMAGE: 'hero image',
      PODCAST_COVER: 'podcast cover',
      EPISODE_COVER: 'episode cover',
      PRODUCT_PICTURE: 'product picture',
      WEBSITE_FAVICON: 'website favicon',
    });
    this.types = {
      ...this.imageTypes,
      EPISODE_AUDIO: 'episode audio',
      YOUTUBE_VIDEO: 'youtube video',
      PRODUCT_FILE: 'product file',
    };
    this.uploadTypes = [
      {
        type: this.types.PRODUCT_FILE,
        acceptedFormats: 'all',
        category: this.categories.file.name,
        maxSize: 100 * 1024 * 1024,
        requirements: `
        Maximum file size 100 MB`,
      },
      {
        type: this.types.LOGO,
        tooltipText: 'Image requirements',
        requirements: `
        At least 100x100 pixels.
        Maximum 500x500 pixels.
        In JPEG or PNG formats.`,
        acceptedFormats: 'image/png, image/jpeg',
        category: this.categories.image.name,
        isSquare: false,
        minWidth: 100,
        minHeight: 100,
        maxWidth: 500,
        maxHeight: 500,
      },
      {
        type: this.types.HERO_IMAGE,
        tooltipText: 'Image requirements',
        requirements: `
        At least 700x550 pixels.
        Maximum 3000x3000 pixels.
        In JPEG or PNG formats.`,
        acceptedFormats: 'image/png, image/jpeg',
        category: this.categories.image.name,
        isSquare: false,
        minWidth: 700,
        minHeight: 550,
        maxWidth: 3000,
        maxHeight: 3000,
      },
      {
        type: this.types.PODCAST_COVER,
        tooltipText: 'Image requirements',
        requirements: `
        At least 1400x1400 pixels.
        Maximum 3000x3000 pixels.
        In JPEG or PNG formats.
        One by one ratio i.e. perfectly square.`,
        acceptedFormats: 'image/png, image/jpeg',
        category: this.categories.image.name,
        isSquare: true,
        minWidth: 1400,
        minHeight: 1400,
        maxWidth: 3000,
        maxHeight: 3000,
      },
      {
        type: this.types.EPISODE_COVER,
        tooltipText: 'Image requirements',
        requirements: `
        At least 1400x1400 pixels.
        Maximum 3000x3000 pixels.
        In JPEG or PNG formats.
        One by one ratio i.e. perfectly square.`,
        acceptedFormats: 'image/png, image/jpeg',
        category: this.categories.image.name,
        isSquare: true,
        minWidth: 1400,
        minHeight: 1400,
        maxWidth: 3000,
        maxHeight: 3000,
      },
      {
        type: this.types.PRODUCT_PICTURE,
        tooltipText: 'Product image requirements',
        requirements: `
        At least 800x800 pixels.
        Maximum 5000x5000 pixels.
        In JPEG or PNG formats.`,
        acceptedFormats: 'image/png, image/jpeg',
        category: this.categories.image.name,
        isSquare: false,
        minWidth: 200,
        minHeight: 150,
        maxWidth: 5000,
        maxHeight: 5000,
      },
      {
        type: this.types.WEBSITE_FAVICON,
        tooltipText: 'Favicon requirements',
        requirements: `
        At least 180X180 pixels.
        Maximum 3000X3000 pixels.
        In PNG format.
        One by one ratio i.e. perfectly square.`,
        acceptedFormats: 'image/png',
        category: this.categories.image.name,
        isSquare: true,
        minWidth: 180,
        minHeight: 180,
        maxWidth: 3000,
        maxHeight: 3000,
      },
      {
        type: this.types.EPISODE_AUDIO,
        tooltipText: 'Audio requirements',
        requirements: 'Your audio file must be a .m4a or .mp3 file.',
        acceptedFormats: 'audio/mpeg, audio/x-mpeg, audio/mp4, audio/x-m4a, audio/x-mp4a',
        category: this.categories.media.name,
      },
      {
        type: this.types.YOUTUBE_VIDEO,
        tooltipText: 'Video requirements',
        requirements: 'Accepted video extensions are: .avi, .flv, .mov, .mp4, .mpg, .wmv, .webm, .3gp and .3g2',
        acceptedFormats: `
        video/quicktime, video/mpeg, video/mp4, video/mpg, video/avi, 
        video/x-ms-wmv, video/x-flv, video/3gpp, video/webm`,
        category: this.categories.media.name,
      },
    ];
    this.getType();
  }

  getType() {
    const type = this.uploadTypes.find((i) => i.type === this.uploadType);
    this.type = type;
    return type;
  }

  getMediaHost(host) {
    if (process.env.NODE_ENV !== 'production') return host;

    switch (this.type.category) {
      case this.categories.media.name:
        return this.categories.media.host;
      case this.categories.file.name:
        return this.categories.file.host;
      case this.categories.image.host:
        return this.categories.image.host;
      default:
        return this.categories.media.host;
    }
  }

  isValidType(type) {
    return Object.values(this.types).includes(type);
  }

  isValidImageType(type) {
    return Object.values(this.imageTypes).includes(type);
  }

  validateImage(width, height) {
    if (width < this.type.minWidth || height < this.type.minHeight) {
      return {
        error: true,
        message: `Image at least ${this.type.minWidth}X${this.type.minHeight} pixels`,
      };
    }
    return {
      error: false,
      message: 'Success',
    };
  }

  isValidSize(file) {
    if (this.type.maxSize) {
      if (file.size > this.type.maxSize) {
        return {
          error: true,
          message: this.type.requirements,
        };
      }
    }
    return {
      error: false,
      message: 'Success',
    };
  }
}

export function hexToHSL(H) {
  // Convert hex to RGB first
  let r = 0; let g = 0; let
    b = 0;
  if (H.length === 4) {
    r = `0x${H[1]}${H[1]}`;
    g = `0x${H[2]}${H[2]}`;
    b = `0x${H[3]}${H[3]}`;
  } else if (H.length === 7) {
    r = `0x${H[1]}${H[2]}`;
    g = `0x${H[3]}${H[4]}`;
    b = `0x${H[5]}${H[6]}`;
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return {
    primary: `hsl(${h},${s}%,${l}%)`,
    dark: `hsl(${h},${s}%,${l - 5}%)`,
    light: `hsl(${h},${s}%,${l + 5}%)`,
  };
}

export function toCamel(s) {
  return s.replace(/([-_][a-z])/ig, ($1) => (
    $1.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  ));
}
