import Vapi from 'vuex-rest-api';

const episode = new Vapi({
  state: {
    episodes: [],
  },
})
  .post({
    action: 'newEpisode',
    property: 'episode',
    path: ({ podcastId }) => `/podcasts/${podcastId}/episodes/`,
  })
  .put({
    action: 'updateEpisode',
    property: 'episode',
    path: ({ podcastId, episodeId }) => `/podcasts/${podcastId}/episodes/${episodeId}`,
  })
  .get({
    action: 'getEpisodes',
    property: 'episodes',
    path: ({ podcastId }) => `/podcasts/${podcastId}/episodes/`,
    queryParams: true,
  })
  .get({
    action: 'getEpisode',
    property: 'episode',
    path: ({ podcastId, episodeId }) => `/podcasts/${podcastId}/episodes/${episodeId}`,
  })
  .delete({
    action: 'deleteEpisode',
    property: 'episode',
    path: ({ podcastId, episodeId }) => `/podcasts/${podcastId}/episodes/${episodeId}`,
  });

export default episode.getStore({
  namespaced: true,
});
