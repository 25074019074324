// store/index.js

import Vue from 'vue';
import Vuex from 'vuex';

import podcast from './modules/podcast';
import product from './modules/product';
import platform from './modules/platform';
import episode from './modules/episode';
import newepisode from './modules/newepisode';
import media from './modules/media';
import user from './modules/user';
import team from './modules/team';
import tab from './modules/tab';
import auth from './modules/auth';
import views from './modules/views';
import category from './modules/category';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    podcast,
    product,
    platform,
    episode,
    newepisode,
    media,
    user,
    team,
    tab,
    auth,
    views,
    category,
  },
});

export default store;
