import Vue from 'vue';
import { Vue as VueIntegration } from '@sentry/integrations';

import Axios from 'axios';

import Buefy from 'buefy';
import '@mdi/font/css/materialdesignicons.css';

import VueI18n from 'vue-i18n';
import VueMeta from 'vue-meta';
import VueMq from 'vue-mq';
import * as Sentry from '@sentry/browser';

import en from './locales/en.json';
import es from './locales/es.json';

import App from './App.vue';
import filters from './filters';
import store from './store';
import router from './router';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn:
      'https://6649983d5b034919b5a1d498009b77ac@o376110.ingest.sentry.io/5196513',
    integrations: [new VueIntegration({ Vue, attachProps: true })],
  });
}

Vue.config.productionTip = false;

Vue.use(Buefy);

Vue.use(VueI18n);

const messages = {
  en,
  es,
};

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
});

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
});

Vue.use(VueMq, {
  breakpoints: {
    mobile: 769,
    tablet: 1024,
    desktop: 1216,
    widescreen: 1408,
    fullhd: Infinity,
  },
});

Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_BASE_URL;

Object.keys(filters).forEach((filterName) => {
  Vue.filter(filterName, filters[filterName]);
});

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
