import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: '/podcasts',
    },
    {
      path: '/login',
      name: 'login',
      component: () => import(/* webpackChunkName: "Login" */ './views/Login.vue'),
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import(/* webpackChunkName: "Login" */ './views/Login.vue'),
      props: (route) => ({
        signup: true,
        priceId: route.query.product,
      }),
    },
    {
      path: '/magic-link',
      name: 'magic-link',
      component: () => import(/* webpackChunkName: "MagicLink" */ './views/MagicLink.vue'),
    },
    {
      path: '/user/:id',
      name: 'user-profile',
      component: () => import(/* webpackChunkName: "UserProfile" */ './views/UserProfile.vue'),
    },
    {
      path: '/podcasts',
      name: 'podcasts',
      component: () => import(/* webpackChunkName: "Podcasts" */ './views/Podcasts.vue'),
    },
    {
      path: '/new-podcast',
      name: 'new-podcast',
      component: () => import(/* webpackChunkName: "NewPodcast" */ './views/NewPodcast.vue'),
    },
    {
      path: '/manage-podcast/:id',
      component: () => import(/* webpackChunkName: "ManagePodcast" */ './layouts/ManagePodcast.vue'),
      children: [
        {
          name: 'podcast.youtube',
          path: 'youtube',
          component: () => import(/* webpackChunkName: "YouTubeConfig" */ './views/YouTube.vue'),
        },
        {
          name: 'podcast.web',
          path: 'web',
          component: () => import(/* webpackChunkName: "WebHero" */ './views/web/Hero.vue'),
        },
        {
          name: 'podcast.web.meta',
          path: 'web/meta',
          component: () => import(/* webpackChunkName: "WebMeta" */ './views/web/Meta.vue'),
        },
        {
          name: 'podcast.web.tabs',
          path: 'web/tabs',
          component: () => import(/* webpackChunkName: "WebTabs" */ './views/web/Tabs.vue'),
        },
        {
          name: 'podcast.web.new',
          path: 'web/new',
          component: () => import(/* webpackChunkName: "WebNewTab" */ './views/web/NewPage.vue'),
        },
        {
          name: 'podcast.web.fonts',
          path: 'web/fonts',
          component: () => import(/* webpackChunkName: "WebFonts" */ './views/web/Fonts.vue'),
        },
        {
          name: 'podcast.web.social',
          path: 'web/social',
          component: () => import(/* webpackChunkName: "WebSocialLinks" */ './views/web/Social.vue'),
        },
        {
          name: 'podcast.web.funding',
          path: 'web/funding',
          component: () => import(/* webpackChunkName: "WebSocialLinks" */ './views/web/Funding.vue'),
        },
        {
          name: 'podcast.status',
          path: 'publish',
          component: () => import(/* webpackChunkName: "PublishPodcast" */ './views/PublishPodcast.vue'),
        },
        {
          name: 'podcast.settings',
          path: 'settings',
          component: () => import(/* webpackChunkName: "BasicPodcastSettings" */ './views/BasicPodcastSettings.vue'),
        },
        {
          name: 'podcast.advanced',
          path: 'advanced-settings',
          component: () => import(/* webpackChunkName: "AdvancedPodcastSettings" */ './views/AdvancedPodcastSettings.vue'),
        },
        {
          name: 'podcast.analytics',
          path: 'analytics',
          component: () => import(/* webpackChunkName: "AnalyticsOverview" */ './views/analytics/Analytics.vue'),
        },
        {
          name: 'podcast.analytics.map',
          path: 'analytics/map',
          component: () => import(/* webpackChunkName: "WorldAnalytics" */ './views/analytics/WorldAnalytics.vue'),
        },
        {
          name: 'podcast.analytics.trends',
          path: 'analytics/trends',
          component: () => import(/* webpackChunkName: "Trends" */ './views/analytics/Trends.vue'),
        },
        {
          name: 'podcast.analytics.players',
          path: 'analytics/players',
          component: () => import(/* webpackChunkName: "PlayerAnalytics" */ './views/analytics/PlayerAnalytics.vue'),
        },
        {
          name: 'podcast.analytics.episodes',
          path: 'analytics/episodes',
          component: () => import(/* webpackChunkName: "EpisodesAnalytics" */ './views/analytics/EpisodesAnalytics.vue'),
        },
        {
          name: 'podcast.products',
          path: 'products',
          component: () => import(/* webpackChunkName: "Products" */ './views/products/Products.vue'),
        },
        {
          name: 'podcast.products.new',
          path: 'products/new',
          component: () => import(/* webpackChunkName: "NewProduct" */ './views/products/NewProduct.vue'),
        },
        {
          name: 'podcast.products.deposits',
          path: 'products/deposits',
          component: () => import(/* webpackChunkName: "Deposits" */ './views/products/Deposits.vue'),
        },
        {
          name: 'podcast.new-episode',
          path: 'new-episode',
          component: () => import(/* webpackChunkName: "NewEpisode" */ './views/episode/New.vue'),
        },
        {
          name: 'podcast.edit-episode',
          path: 'edit-episode/:episodeId',
          component: () => import(/* webpackChunkName: "EditEpisode" */ './views/episode/New.vue'),
        },
        {
          name: 'podcast.episodes',
          path: 'episodes',
          component: () => import(/* webpackChunkName: "ViewEpisodes" */ './views/ViewEpisodes.vue'),
        },
        {
          name: 'podcast.transcripts',
          path: 'transcripts',
          component: () => import(/* webpackChunkName: "ViewTranscripts" */ './views/ViewTranscripts.vue'),
        },
        {
          name: 'podcast.edit-transcript',
          path: 'edit-transcript/:episodeId',
          component: () => import(/* webpackChunkName: "EditTranscript" */ './views/EditTranscript.vue'),
        },
        {
          name: 'podcast.team',
          path: 'team',
          component: () => import(/* webpackChunkName: "SetupTeam" */ './views/SetupTeam.vue'),
        },
        {
          name: 'podcast.player',
          path: 'player',
          component: () => import(/* webpackChunkName: "SetupPlayer" */ './views/SetupPlayer.vue'),
        },
      ],
    },
    { path: '/403', component: () => import(/* webpackChunkName: "NotAuthorized" */ './views/NotAuthorized.vue') },
    { path: '/503', component: () => import(/* webpackChunkName: "Maintenance" */ './views/Maintenance.vue') },
    { path: '/404', alias: '*', component: () => import(/* webpackChunkName: "NotFound" */ './views/NotFound.vue') },
  ],
});

router.beforeEach((to, from, next) => {
  if (process.env.VUE_APP_MAINTENANCE_MODE === 'true') {
    if (to.path !== '/503') {
      next('/503');
    } else {
      next();
    }
  } else if (
    to.name !== 'login'
    && to.name !== 'signup'
    && to.name !== 'magic-link'
    && !store.getters['auth/isLoggedIn']
  ) {
    next('/login');
  } else {
    next();
  }
});

export default router;
