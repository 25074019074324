<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { composeErrorMsg, checkNested } from './utils';

export default {
  created() {
    const refreshAuthLogic = () => this.$store.dispatch('auth/refreshAccessToken').then(() => Promise.resolve());

    createAuthRefreshInterceptor(this.$http, refreshAuthLogic);

    const vm = this;

    this.$http.interceptors.request.use((config) => {
      let token = '';

      if (
        Object.prototype.hasOwnProperty.call(config, 'refresh')
        && config.refresh
      ) {
        token = vm.$store.getters['auth/getRefresh'];
      } else {
        token = vm.$store.getters['auth/getToken'];
      }

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    });

    this.$http.interceptors.request.use(
      (config) => config,
      /*
         * The request was made but no response was received, `error.request`
         * is an instance of XMLHttpRequest in the browser and an instance
         * of http.ClientRequest in Node.js
         */
      (err) => Promise.reject(err),
    );

    this.$http.interceptors.response.use(
      (response) => response,
      (err) => {
        /*
         * The request was made and the server responded with a
         * status code that falls out of the range of 2xx
         */

        if (
          Object.prototype.hasOwnProperty.call(err.config, 'errorHandle')
          && err.config.errorHandle === false
        ) {
          return Promise.reject(err);
        }

        if (err.response.status === 404) {
          vm.$router.push('/404');
          return Promise.reject(err);
        }

        if (err.response.status === 403) {
          vm.$router.push('/403');
          return Promise.reject(err);
        }

        if (err.response.status === 401) {
          return Promise.reject(err);
        }

        let errorMessage = '';

        if (checkNested(err, 'response', 'data', 'errors')) {
          errorMessage = composeErrorMsg(err.response.data.errors);
        } else if (checkNested(err, 'response', 'data', 'message')) {
          errorMessage = err.response.data.message;
        } else {
          errorMessage = err.message;
        }

        vm.$buefy.toast.open({
          message: errorMessage,
          type: 'is-danger',
          duration: 4500,
        });

        return Promise.reject(err);
      },
    );
  },
  metaInfo: {
    titleTemplate: '%s | caproni',
  },
};
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";

@font-face {
  font-family: "Baloo 2";
  src: url("~typeface-baloo-2/files/baloo-2-latin-400.woff2")
    format("woff2");
}

 @font-face {
  font-family: "Montserrat";
  src: url("~typeface-montserrat/files/montserrat-latin-400.woff2")
  format("woff2");
}

$title-family: "Baloo 2", "Arial", serif;
$family-primary: "Montserrat", "Arial", sans-serif;

// Set your colors
$primary: #8c67ef;
$deep-purple: #241942;
$deep-purple-light: findLightColor($deep-purple);
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$twitter: #4099FF;
$twitter-invert: findColorInvert($twitter);

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
    (
        "white": (
            $white,
            $black,
        ),
        "black": (
            $black,
            $white,
        ),
        "light": (
            $light,
            $light-invert,
        ),
        "dark": (
            $dark,
            $dark-invert,
        ),
        "primary": (
            $primary,
            $primary-invert,
            $primary-light,
            $primary-dark,
        ),
        "link": (
            $link,
            $link-invert,
            $link-light,
            $link-dark,
        ),
        "info": (
            $info,
            $info-invert,
            $info-light,
            $info-dark,
        ),
        "success": (
            $success,
            $success-invert,
            $success-light,
            $success-dark,
        ),
        "warning": (
            $warning,
            $warning-invert,
            $warning-light,
            $warning-dark,
        ),
        "danger": (
            $danger,
            $danger-invert,
            $danger-light,
            $danger-dark,
        ),
         "deep-purple": (
            $deep-purple,
            $deep-purple-light
        ),
    ),
    $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import "~@creativebulma/bulma-divider";
</style>
