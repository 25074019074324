import axios from 'axios';

const actions = {
  requestOnboardStatus({ commit }, podcastId) {
    axios.get(`podcasts/${podcastId}/onboard-complete/`)
      .then((resp) => {
        commit('set_onboard_status', resp.data);
      });
  },
};

const mutations = {
  set_onboard_status(state, payload) {
    state.onboardStatus = payload;
  },
};

const getters = {
  getOnboardStatus: (state) => state.onboardStatus,
  getOnboardComplete: (state) => Object.values(state.onboardStatus).every(Boolean),
};

const state = {
  onboardStatus: {},
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
