import Vapi from 'vuex-rest-api';

const tab = new Vapi({
  state: {
    tabs: [],
  },
})
  .post({
    action: 'newTab',
    property: 'tabs',
    path: ({ podcastId }) => `/podcasts/${podcastId}/tabs/`,
  })
  .put({
    action: 'updateTab',
    property: 'tab',
    path: ({ podcastId, tabId }) => `/podcasts/${podcastId}/tabs/${tabId}`,
  })
  .get({
    action: 'getTabs',
    property: 'tabs',
    path: ({ podcastId }) => `/podcasts/${podcastId}/tabs/`,
  })
  .get({
    action: 'getTab',
    property: 'tab',
    path: ({ podcastId, tabId }) => `/podcasts/${podcastId}/tabs/${tabId}`,
  })
  .delete({
    action: 'deleteTab',
    property: 'tabs',
    path: ({ podcastId, tabId }) => `/podcasts/${podcastId}/tabs/${tabId}`,
  });

export default tab.getStore({
  namespaced: true,
});
