import Vapi from 'vuex-rest-api';

const product = new Vapi({
  state: {
    products: [],
  },
})
  .post({
    action: 'newProduct',
    property: 'product',
    path: ({ podcastId }) => `/podcasts/${podcastId}/products/`,
  })
  .put({
    action: 'updateProduct',
    property: 'product',
    path: ({ podcastId, productId }) => `/podcasts/${podcastId}/products/${productId}`,
  })
  .get({
    action: 'getProducts',
    property: 'products',
    path: ({ podcastId }) => `/podcasts/${podcastId}/products/`,
  })
  .get({
    action: 'getProduct',
    property: 'product',
    path: ({ podcastId, productId }) => `/podcasts/${podcastId}/products/${productId}`,
  })
  .delete({
    action: 'deleteProduct',
    property: 'product',
    path: ({ podcastId, productId }) => `/podcasts/${podcastId}/products/${productId}`,
  });

export default product.getStore({
  namespaced: true,
});
