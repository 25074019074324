import Vapi from 'vuex-rest-api';

const team = new Vapi({
  state: {
    teammembers: [],
  },
})
  .post({
    action: 'newTeamMember',
    property: 'teammembers',
    path: ({ podcastId }) => `/podcasts/${podcastId}/team/`,
  })
  .put({
    action: 'updateTeamMember',
    property: 'teammember',
    path: ({ podcastId, userId }) => `/podcasts/${podcastId}/team/${userId}`,
  })
  .get({
    action: 'getTeamMembers',
    property: 'teammembers',
    path: ({ podcastId }) => `/podcasts/${podcastId}/team/`,
  })
  .get({
    action: 'getTeamMember',
    property: 'teammember',
    path: ({ podcastId, userId }) => `/podcasts/${podcastId}/team/${userId}`,
  })
  .delete({
    action: 'deleteTeamMember',
    property: 'teammembers',
    path: ({ podcastId, userId }) => `/podcasts/${podcastId}/team/${userId}`,
  });

export default team.getStore({
  namespaced: true,
});
